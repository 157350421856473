<template>
  <!-- NAME[epic=绩效] 任务管理 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="type">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="任务类型"
        >
          <el-option
            v-for="(i, idx) in typeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="name">
        <el-input v-model="form.name" placeholder="员工姓名、电话"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              handleQuery()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleCreate">新建任务</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '关联员工'">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="setStaff(row.user_ids, row.id)"
            >
              {{ row.user_ids == '' ? 0 : row.user_ids.split(',').length }}
            </span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleEditRow($index, row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleStopRow($index, row)"
          >
            <span v-if="row.status == 1">停止</span>
            <span v-if="row.status == 2">启动</span>
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleDeleteRow($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <set-staff
      ref="setStaff"
      :staffids="staffids"
      :is-sub="true"
      @submit="saveStaff"
    ></set-staff>
    <create ref="create" @refresh="handleQuery"></create>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    getTableList,
    deleteMission,
    changeMissionStatus,
    subStaff,
  } from '@/api/performance'
  import SetStaff from '@/baseComponents/setStaff'
  import Create from './components/create'
  export default {
    name: 'TaskManagement',
    components: { SetStaff, Create },
    data() {
      return {
        loading: false,
        // 1拜访2建店3销售
        typeList: [
          {
            id: 1,
            name: '拜访任务',
          },
          {
            id: 2,
            name: '建店任务',
          },
          {
            id: 3,
            name: '销售任务',
          },
        ], // 任务类型下拉
        form: {
          pageSize: 10,
          pageNo: 1,
          name: '',
          type: '', //任务类型
        },
        staffids: '',
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        list: [],
        checkList: ['任务名称', '任务类型', '优先级', '任务描述', '关联员工'],
        columns: [
          {
            order: 1,
            label: '任务名称',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '任务类型',
            prop: 'type_text',
            width: '',
          },
          {
            order: 3,
            label: '优先级',
            prop: 'level',
            width: '',
          },
          {
            order: 4,
            label: '任务描述',
            prop: 'note',
            width: '',
          },
          {
            order: 5,
            label: '关联员工',
            prop: 'user_ids',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.handleQuery()
    },
    methods: {
      async handleQuery() {
        this.loading = true
        let { data, totalCount } = await getTableList(this.form)
        this.list = data
        this.total = Number(totalCount) || 0
        console.log(data)
        this.loading = false
      },
      handleCreate() {
        this.$refs.create.title = '添加任务'
        this.$refs.create.isEdit = false
        this.$refs.create.showDialog = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.handleQuery()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handleQuery()
      },
      // 管理员工
      setStaff(ids, id) {
        console.log(ids)
        this.staffids = ids
        this.$refs.setStaff.id2 = id
        this.$refs.setStaff.showDialog = true
      },

      // 表格操作
      handleEditRow(index, row) {
        this.$refs.create.form.id = row.id
        this.$refs.create.isEdit = true
        this.$refs.create.title = '修改任务'
        this.$refs.create.showDialog = true
      },
      handleStopRow(index, row) {
        changeMissionStatus({ id: row.id }).then((res) => {
          this.handleQuery()
          this.$message.success(res.msg)
        })
      },
      handleDeleteRow(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteMission({ id: row.id }).then((res) => {
              this.handleQuery()
              this.$message.success(res.msg)
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      saveStaff(data) {
        console.log('submit', data)
        subStaff(data).then((res) => {
          console.log(res)
          this.$message.success(res.msg)
          this.$refs.setStaff.showDialog = false
          this.handleQuery()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
