<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :close-on-click-modal="false"
    center
  >
    <div class="orderTest-container">
      <el-button-group v-show="!isEdit">
        <el-button
          v-for="(item, index) in nav"
          :key="index"
          :type="form.type == index + 1 ? 'primary' : ''"
          @click="form.type = index + 1"
        >
          {{ item }}
        </el-button>
      </el-button-group>
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
        style="margin-top: 15px"
      >
        <el-form-item label="任务名称:" style="width: 100%" prop="name">
          <el-input v-model="form.name" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.type == 1"
          label="每日拜访家数:"
          style="width: 100%"
          prop="count"
        >
          <el-input
            v-model.number="form.count"
            placeholder="请输入数字"
            style="width: 240px"
            type="number"
          >
            <template slot="append">家</template>
          </el-input>
          <div v-if="form.type == 1">月度目标=每日拜访家数X当月天数</div>
        </el-form-item>
        <el-form-item
          v-if="form.type == 2"
          label="每日建店家数:"
          style="width: 100%"
          prop="count"
        >
          <el-input
            v-model.number="form.count"
            placeholder="请输入数字"
            style="width: 240px"
            type="number"
          >
            <template slot="append">家</template>
          </el-input>
          <div v-if="form.type == 2">月度目标=每日建店家数X当月天数</div>
        </el-form-item>
        <el-form-item
          v-if="form.type == 3"
          label="月度目标:"
          style="width: 100%"
          prop="money"
        >
          <el-input
            v-model.number="form.money"
            type="number"
            placeholder="请输入数字"
            style="width: 240px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="任务描述:" style="width: 100%" prop="note">
          <el-input
            v-model="form.note"
            style="width: 240px"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级:" style="width: 100%" prop="level">
          <el-input v-model="form.level" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!isEdit" type="primary" @click="handleSubmit">
        提交
      </el-button>
      <el-button v-else type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { createMission, missionDetail, editMission } from '@/api/performance'
  export default {
    name: 'Create',
    components: {},
    data() {
      return {
        isEdit: false,
        title: '添加任务',
        nav: ['拜访任务', '建店任务', '销售任务'],
        showDialog: false,
        form: {
          id: null,
          name: '', //任务名称
          type: 1, //1拜访2建店3销售
          note: '', //备注
          count: '', //(拜访，建店用)
          money: '', //金额(销售任务用)
          level: '', //级别
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          if (this.isEdit) {
            this.fetchData()
          }
        } else {
          this.$refs.form.resetFields()
          this.form.type = 1
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        let { data } = await missionDetail({ id: this.form.id })
        this.form = data[0]
      },
      // 修改
      handleSave() {
        editMission(this.form).then((res) => {
          this.$emit('refresh')
          this.close()
          this.$message.success(res.msg)
        })
      },
      handleSubmit() {
        createMission(this.form).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
